/* eslint-disable */
import '../../components/buttons/mainBtn.css';
import {useTranslation} from 'react-i18next';
import ErrorPage from '../errorPage/ErrorPage';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import {api, useGetBloodyLitersSavedQuery} from '../../redux/api';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  getGeolocationWithPermission
} from '../../hookServices/getGeolocationWithPermission';
import {ENV} from '../../environment';
import './HomePage.css';
import SVGContainer from '../../components/svgComponents/SVGContainer';
import SVGBanner from '../../components/svgComponents/SVGBanner';
import {getCookie} from '../../hookServices/cookiesManager';
import Map from "./Map";

export default function HomeMapPage({fingerPrint}) {
  const {
    data: bloodyLitters,
    isLoading: bloodyIsLoading
  } = useGetBloodyLitersSavedQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const {t} = useTranslation();

  const [getStationStatusAll] = api.useLazyGetStationStatusAllQuery();
  const [getStationStatusNearest] = api.useLazyGetNearestStationQuery();

  const [isLocation, setIsLocation] = useState(false);
  const [stationsArr, setStationsArr] = useState();

  const [allStationsStatus, setAllStationStatus] = useState(null);
  const [errorAllStationsStatus, setErrorAllStationsStatus] = useState();
  const [setLoadingAllStationsStatus] = useState();

  const [nearestStationsStatus, setNearestStationStatus] = useState(null);
  const [errorNearestStationStatus, setErrorNearestStationStatus] = useState();
  const [loadingNearestStationStatus, setLoadingNearestStationStatus] = useState();

  const [isOpenScanner, setIsOpenScanner] = useState(false);

  const [currentBalance, setCurrentBalance] = useState(null);

  const showScannerButton = ENV === 'stage';

  const COST_PER_KWT = 15;
  const INITIAL_KWT = 44;

  const getAllStations = async () => {
    if (!allStationsStatus) {
      const {
        data,
        isSuccess,
        isLoading,
        isError,
        error
      } = await getStationStatusAll(fingerPrint);
      if (isLoading && !isSuccess && !isError) {
        setLoadingAllStationsStatus(true);
        setLoadingNearestStationStatus(false);
      }
      if (isSuccess && !isError) {
        setAllStationStatus(data);
        setNearestStationStatus(null);
      }
      if (!isSuccess && isError) {
        setErrorAllStationsStatus(error);
        setErrorNearestStationStatus(undefined);
      }
    }
  };
  const getNearestStation = async position => {
    const {
      data,
      isSuccess,
      isLoading,
      isError,
      error
    } = await getStationStatusNearest({
      lat: position.latitude,
      lon: position.longitude,
      top: '',
      distanceM: '',
      fingerPrint,
    });
    if (isLoading && !isSuccess && !isError) {
      setLoadingAllStationsStatus(false);
      setLoadingNearestStationStatus(true);
    }
    if (isSuccess && !isError) {
      setAllStationStatus(null);
      setNearestStationStatus(data);
    }
    if (!isSuccess && isError) {
      setErrorAllStationsStatus(undefined);
      setErrorNearestStationStatus(error);
    }
  };

  function handleGeolocationSuccess(position) {
    getNearestStation(position.coords);
  }

  function handleGeolocationError(error) {
    getAllStations();
  }

  function getGeolocation() {
    const position = getCookie('userLocation');
    if (position) {
      getNearestStation(position);
    } else {
      getGeolocationWithPermission(getAllStations, handleGeolocationError,
          handleGeolocationSuccess);
    }
  }

  useEffect(() => {
    getGeolocation();
  }, []);

  useEffect(() => {
    if (allStationsStatus && !nearestStationsStatus) {
      setStationsArr(allStationsStatus);
    }
    if (!allStationsStatus && nearestStationsStatus) {
      setStationsArr(nearestStationsStatus);
    }
  }, [allStationsStatus, nearestStationsStatus]);

  if ((errorNearestStationStatus && errorNearestStationStatus !== 400)
      || errorAllStationsStatus) {
    return <ErrorPage errorHeader={t('errorDevHeader')}
                      errorBody={t('errorDevBody')}/>;
  }
  if (!stationsArr) {
    return (
        <div style={{height: '100lvh', width: '100lvw'}}>
          <Spinner/>
        </div>
    );
  }

  function showMap() {
    return <Map ports={stationsArr}/>;
  }

  return (
      <div id="homePage" style={{margin: '10lvh 0 5lvh'}}>
        {showMap()}
        {/*{showList()}*/}
        <div
            onClick={() => {
              navigate('/scanner');
            }}
            className={'qr-button'}
            style={{top: '74lvh'}}
        >
          <SVGContainer>
            <SVGBanner/>
          </SVGContainer>
        </div>
      </div>
  );
}

/* eslint-disable */

import React, {useRef, useEffect} from 'react';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './Map.css';
import * as maptilersdk from '@maptiler/sdk';

export default function Map({ports}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 31.223;
  const lat = 45.454;
  const zoom = 4;
  const API_KEY = 'fysAbJpnonqUYEipElqb';
  maptilersdk.config.apiKey = API_KEY;

  useEffect(() => {
    if (map.current) {
      return;
    } // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.STREETS,
      // navigationControl: false,

      center: [lng, lat],
      zoom: zoom,
      // geolocate: maptilersdk.GeolocationType.POINT,

      // geolocateControl: false,
      // navigationControl: false,

      maptilerLogo: false,
      scaleControl: true,
    });


    drawPorts();

  }, [API_KEY, lng, lat, zoom]);

  function drawPorts() {
    ports.forEach(function (port) {
      // console.log(port)
      var marker = {
        'type': 'Feature',
        'properties': {
          'message': 'Baz',
          'iconSize': [40, 40]
        },
        'geometry': {
          'type': 'Point',
          'coordinates': [
            parseFloat(`${port.longitude}`),
            parseFloat(`${port.latitude}`),
          ],
        }
      }

      var el = document.createElement('div');
      el.style.width = '30px';
      el.style.height = '30px';

      if (port?.name?.includes('toka-')) {
        el.style.backgroundImage = `url(/chargerToka.svg)`;
        el.addEventListener('click', function () {
          window.open(
              `https://www.google.com/maps/dir//${port.latitude},${port.longitude}/@${port.latitude},${port.longitude},12z`)
          // window.open(isIos()
          //     ?
          //     `https://itunes.apple.com/ua/app/toka-network/id1320781993`
          //     :
          //     `https://play.google.com/store/apps/details?id=com.tokamobile.TokaApp`);
        });
      } else if (port?.name.includes('ef-')) {
        el.style.backgroundImage = `url(/chargerEF.svg)`;
        el.addEventListener('click', function () {
          window.open(
              `https://www.google.com/maps/dir//${port.latitude},${port.longitude}/@${port.latitude},${port.longitude},12z`)
          // window.open(isIos()
          //     ?
          //     `https://apps.apple.com/ua/app/ecofactor-network/id1438716797?l=ru`
          //     :
          //     `https://play.google.com/store/apps/details?id=eu.ecofactor&hl=ru&gl=US`);
        });
      } else if (port?.name.includes('g2y-')) {
        el.style.backgroundImage = `url(/chargerG2Y.svg)`;
        el.addEventListener('click', function () {
          window.open(
              `https://www.google.com/maps/dir//${port.latitude},${port.longitude}/@${port.latitude},${port.longitude},12z`)
          // window.open(isIos()
          //     ?
          //     `https://apps.apple.com/app/to-u/id1175538017`
          //     :
          //     `https://play.google.com/store/apps/details?id=com.go.tou`);
        });
      } else {
        el.className = port?.job?.state === 'IN_PROGRESS' ? 'blink_me' : null;
        el.style.backgroundImage =
            port.online ? `url(/port.svg)` : `url(/portOffline.svg)`;
        el.addEventListener('click', function () {
          window.open(`/start?station=${port.number}`);
        });
      }

      new maptilersdk.Marker({element: el})
      .setLngLat(marker.geometry.coordinates)
      .addTo(map.current);
    });
  }

  function isIos() {
    return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on isIos 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
      <div className="map-wrap">
        <div ref={mapContainer} className="map"/>
      </div>
  );
}
